import { FC, useCallback } from 'react';

import { DatepickerFilter, SearchFilter } from '@/shared/components';
import { CategoryFilters } from '@/features/categories/components/category-filters';
import { jsonParse, jsonStringify } from '@/shared/utils';
import { DEFAULT_TABLE_ITEMS_SIZE, FILTERS_TABLE_KEY, PAGINATION_KEY } from '@/shared/constants';
import { useQueryParams } from '@/shared/hooks';

import styles from './filters.module.scss';

type FiltersProps = {
  shownSearch?: boolean;
  shownDateRange?: boolean;
  shownCategories?: boolean;
};

export const Filters: FC<FiltersProps> = ({ shownSearch, shownDateRange, shownCategories }) => {
  const { queryParams, setQueryParams } = useQueryParams();

  const handleSubmitCategoryFilter = (categories: string[]) => {
    setQueryParams((prev) => {
      const prevFilters = jsonParse(prev?.[FILTERS_TABLE_KEY]);

      return {
        [FILTERS_TABLE_KEY]: jsonStringify({
          ...(prevFilters ? prevFilters : {}),
          categoryIds: categories,
        }),
        // When categories have been applied, the table should start from the first page
        [PAGINATION_KEY]: jsonStringify({ itemsPerPage: DEFAULT_TABLE_ITEMS_SIZE, page: 0 }),
      };
    });
  };

  const handleSubmitDateRangeFilter = (dateRange?: [string, string]) => {
    setQueryParams((prev) => {
      const prevFilters = jsonParse(prev?.[FILTERS_TABLE_KEY]);

      return {
        [FILTERS_TABLE_KEY]: jsonStringify({
          ...(prevFilters ? prevFilters : {}),
          dateRange: dateRange || [],
        }),
        // When the Data Range filter have been applied, the table should start from the first page
        [PAGINATION_KEY]: jsonStringify({ itemsPerPage: DEFAULT_TABLE_ITEMS_SIZE, page: 0 }),
      };
    });
  };

  const clearSearchFilter = useCallback(() => {
    setQueryParams((prev) => {
      const prevFilters = jsonParse(prev?.[FILTERS_TABLE_KEY]);

      return {
        [FILTERS_TABLE_KEY]: jsonStringify({
          ...(prevFilters ? prevFilters : {}),
          keyword: '',
        }),
      };
    });
  }, [setQueryParams]);

  const handleSubmitSearch = useCallback(
    (keyword = '') => {
      const MIN_LENGHT = 2;

      if (!keyword) {
        clearSearchFilter();
        return;
      }

      if (keyword.length < MIN_LENGHT) return;

      setQueryParams((prev) => {
        const prevFilters = jsonParse(prev?.[FILTERS_TABLE_KEY]);

        return {
          [FILTERS_TABLE_KEY]: jsonStringify({
            ...(prevFilters ? prevFilters : {}),
            keyword,
          }),
          // When search have been applied, the table should start from the first page
          [PAGINATION_KEY]: jsonStringify({ itemsPerPage: DEFAULT_TABLE_ITEMS_SIZE, page: 0 }),
        };
      });
    },
    [clearSearchFilter, setQueryParams],
  );

  const searchedKeyword = jsonParse<{ keyword: string }>(queryParams[FILTERS_TABLE_KEY])?.keyword;
  const selectedCategories = jsonParse<{ categoryIds?: string[] }>(queryParams[FILTERS_TABLE_KEY])?.categoryIds;
  const selectedDateRange = jsonParse<{ dateRange?: [string, string] }>(queryParams[FILTERS_TABLE_KEY])?.dateRange;

  return (
    <div className={styles['filters']}>
      {shownSearch && (
        <SearchFilter searchedKeyword={searchedKeyword} onSubmit={handleSubmitSearch} onClear={clearSearchFilter} />
      )}

      {shownDateRange && <DatepickerFilter selectedRange={selectedDateRange} onSubmit={handleSubmitDateRangeFilter} />}

      {shownCategories && (
        <CategoryFilters selectedSubcategoryIds={selectedCategories} onSubmit={handleSubmitCategoryFilter} />
      )}
    </div>
  );
};
